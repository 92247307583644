import { ReactComponent as SpiralLoading } from 'assets/modalLoading.svg';
import React from 'react';

interface Props
{
    loading?: boolean;
    maskedColor?: string;
    title?: string;
}

export const FormLoading = React.memo(({maskedColor = 'rgba(255, 255, 255, 0.5)', title = 'Loading...', loading = false}: Props): JSX.Element =>
{
    return (
        <div
            className="loading"
            style={{
                display: loading ? 'flex' : 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 1005,
                top: 0,
                left: 0,
                backgroundColor: maskedColor,
            }}
        >
            <SpiralLoading />
            <span>{title}</span>
        </div>
    );
});
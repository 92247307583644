export interface UserLoginDto
{
    username: string;
    password: string;
    loginType: LoginType
}

export enum LoginType{
    WEB,
    MOBILE
}
export enum Gender {
    MALE,
    FEMALE,
    OTHER,
}

export interface ComboOptions<T> {
    label: string;
    value: T;
}

export type Identifier = string | number;

export interface BaseEntity {
    id: Identifier;
}

export interface AuditLogEntity extends BaseEntity {
    createdTime: Date;
    updatedTime: Date;
    createdBy: string;
    updatedBy: string;
}

export interface LoginResponseDto {
    token: string;
    userInfo: UserDetailDto;
}

export interface UserDetailDto {
    fullName: string;
    imageUrl: string;
    roles: Array<string>;
}
export interface HisAuditLog {
    createdAt?: string;
    createdBy?: number;
    updatedAt?: string;
    updatedBy?: number;
}

export interface HisBasePatientInfo {
    id: number;
    maHoSo: string;
    maNb: string;
    maBenhAn: string;
    tenNb: string;
    ngaySinh: Date;
    chiNamSinh: boolean;
    gioiTinh: number;
    doiTuongKcb: number;
    phongId?: number;
    giuongId?: number;
    soNha?: string;
    xaPhuongId?: number;
    quanHuyenId?: number;
    tinhThanhPhoId?: number;
    quocGiaId?: number;
    diaChi?: string;
    soDienThoai?: string;
    tenNguoiBaoLanh?: string;
    sdtNguoiBaoLanh?: string;
    soCanCuocNguoiBaoLanh?: string;
    email?: string;
    ngheNghiepId?: number;
    danTocId?: number;
    quocTichId?: number;
    anhDaiDien: string;
    capCuu: boolean;
    uuTien: boolean;
    doiTuong: number;
    iscMaDatKham?: string;
    tuoi?: number;
    ngaySinh2: string;
    tuoi2: string;
    thangTuoi?: number;
    maQuanHuyen?: string;
    tenQuanHuyen?: string;
    maTinhThanhPho: string;
    tenTinhThanhPho: string;
    maQuocGia: string;
    tenQuocGia: string;
    maXaPhuong: string;
    tenXaPhuong: string;
    noiTru: boolean;
    ngayVaoVien: Date;
    khoa?: string;
    soTheBaoHiem?: string;
    soCanCuoc?: string;
    anhCanCuocTruoc?: string;
    anhCanCuocSau?: string;
    khamSucKhoeHopDong: boolean;
    dieuTriCovid: boolean;
}

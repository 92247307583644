import '../style/form-login.scss';

import { Button, Checkbox, Form, Input } from 'antd';
import { LoginType, UserLoginDto } from 'app/type';
import _ from 'lodash';
import {nameof} from 'ts-simple-nameof';

import { FormLoginType } from '../interface/LoginType';

export const FormLogin = ({ onCheckLogin }: FormLoginType) => {
    const onFinish = async (value: UserLoginDto) =>
    {
        value.loginType = LoginType.WEB;
        onCheckLogin(value);
    };

    const onFinishFailed = () => {
        console.log('on finish fail');
    };
    return (
        <div className='form-content'>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Tên đăng nhập"
                    name={nameof<UserLoginDto>(x => x.username)}
                    rules={[{ required: true, message: 'Tên đăng nhập!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Mật khẩu"
                    name={nameof<UserLoginDto>(x => x.password)}
                    rules={[{ required: true, message: 'Mật khẩu!' }]}
                >
                    <Input.Password />
                </Form.Item>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Form.Item name="remember" valuePropName="checked" style={{ width: '100%' }}>
                        <Checkbox>Lưu mật khẩu</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Đăng Nhập
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>

    );
};
import { checkLogin, selectAuth } from 'app/authSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Loading from 'components/loading/Loading';
import LoginView from 'module/login/LoginView';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
    children: JSX.Element
}

export const AuthProvider: React.FC<Props> = (props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { loading, authenticated } = useAppSelector(selectAuth);
    const location = useLocation();
    const isPublicRoute = location.pathname.includes('/public');
    useEffect(() =>
    {
        if(!isPublicRoute)
            dispatch(checkLogin());
    }, []);

    if (loading) return <Loading />;
    if(authenticated || isPublicRoute) return props.children;

    return (
        <LoginView />
    );
};
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'components/style/grid.scss';

import { ColDef, ICellRendererParams } from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { Button, Popconfirm, Select } from 'antd';
import { BaseGridController, ButtonToolbar, ColumnActions, GridController, GridModel } from 'components/grid/type';
import Loading from 'components/loading/Loading';
import React, { useMemo, useRef } from 'react';
import { ComboOptions } from 'types';

interface Props extends AgGridReactProps {
    columnDefs: Array<ColDef>;
    columnActions?: Array<ColumnActions<any>> | null;
    buttonsToolbar?: Array<ButtonToolbar> | null;
    rowsCount?: boolean | null;
    gridController: Omit<GridController, keyof BaseGridController>;
    floatingFilter?: boolean;
    hasSelectPageSize?: boolean;
    pagination?: boolean;
}

export const HGrid = React.memo((props: Props): JSX.Element => {
    const {
        buttonsToolbar,
        columnActions,
        columnDefs,
        rowsCount,
        gridController,
        floatingFilter,
        hasSelectPageSize,
        ...restProps
    } = props;
    const { onChangePageSize, ...restGridController } = gridController;
    const gridRef = useRef<AgGridReact>(null);
    const newColumnDefs = useMemo(() => addDefaultColumns(columnActions || [], columnDefs, rowsCount || false), []);

    const renderButtonToolbar = () => {
        return buttonsToolbar?.map((button, index) => {
            const { onClick, name, ...rest } = button;
            return (
                <Button {...rest} key={index} onClick={() => onClick()}>
                    {name}
                </Button>
            );
        });
    };

    return (
        <div className="h-container">
            <div className="flex px-5 py-2 justify-end">{renderButtonToolbar()}</div>
            <div className="h-container__h-grid">
                <AgGridReact
                    className="ag-theme-alpine"
                    ref={gridRef}
                    {...restProps}
                    {...restGridController}
                    columnDefs={newColumnDefs}
                    rowModelType={'infinite'}
                    loadingOverlayComponent={Loading}
                    overlayNoRowsTemplate={'<div>Empty</div>'}
                    enableRangeSelection
                    animateRows={true}
                    maxBlocksInCache={1}
                    defaultColDef={{
                        floatingFilter: floatingFilter,
                        suppressMenu: true,
                        filterParams: {
                            filterOptions: ['contains', 'equals'],
                            defaultOption: 'contains',
                            suppressAndOrCondition: true,
                        },
                    }}
                ></AgGridReact>
                <div className="absolute bottom-2 right-[350px]">
                    {hasSelectPageSize && (
                        <Select
                            className="min-w-[85px]"
                            defaultValue={restGridController.paginationPageSize}
                            options={pageSizeCombo}
                            onChange={(value: number) => onChangePageSize(value)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

const pageSizeCombo: Array<ComboOptions<number>> = [
    {
        label: '10',
        value: 10,
    },
    {
        label: '20',
        value: 20,
    },
    {
        label: '50',
        value: 50,
    },
    {
        label: '100',
        value: 100,
    },
    {
        label: 'Tất cả',
        value: -1,
    },
];

const addDefaultColumns = (
    columnActions: ColumnActions<GridModel>[],
    columnDefs: ColDef[],
    rowsCount: boolean,
): Array<ColDef> => {
    const newColumnDefs = [...columnDefs];
    if (columnActions && columnActions.length !== 0) {
        const actions = {
            field: 'action',
            headerName: 'Hành động',
            headerClass: 'action-header',
            pinned: 'right',
            cellStyle: { border: 'none' },
            minWidth: 220,
            maxWidth: 250,
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}
                    >
                        {columnActions?.map((action: ColumnActions<GridModel>, index) => {
                            const { isConfirm, onClick, name, ...actionProps } = action;
                            return isConfirm ? (
                                <Popconfirm
                                    key={index}
                                    placement="left"
                                    title="Bạn có chắc xóa?"
                                    okText="Đồng ý"
                                    cancelText="Hủy"
                                    onConfirm={() => onClick(params.data)}
                                >
                                    <Button
                                        style={{
                                            ...action.style,
                                            borderRadius: 5,
                                            minWidth: '50px',
                                            boxShadow: '3px 6px #c7c7c7',
                                            marginRight: '5px',
                                            marginLeft: '5px',
                                        }}
                                        {...actionProps}
                                    >
                                        {name}
                                    </Button>
                                </Popconfirm>
                            ) : (
                                <Button
                                    key={index}
                                    style={{
                                        ...action.style,
                                        borderRadius: 5,
                                        minWidth: '50px',
                                        boxShadow: '3px 6px #c7c7c7',
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                    }}
                                    {...actionProps}
                                    onClick={() => onClick(params.data)}
                                >
                                    {action.name}
                                </Button>
                            );
                        })}
                    </div>
                );
            },
        } as ColDef;
        newColumnDefs.push(actions);
        // newColumnDefs.push({ field: nameof<BaseEntity>((x) => x.id), hide: true } as ColDef);
    }
    if (rowsCount)
        newColumnDefs.unshift({
            field: 'STT',
            headerName: 'STT',
            valueGetter: 'node.rowIndex + 1',
            maxWidth: 100,
            pinned: 'left',
        } as ColDef);

    return newColumnDefs;
};

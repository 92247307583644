import { selectAuth } from 'app/authSlice';
import { useAppSelector } from 'app/hooks';
import { Error } from 'components/error/Error';
import { getMenusHasPermission } from 'components/layout/AppLayout';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface IProps {
    children: JSX.Element;
}

export const PrivateRoute = (props: IProps): JSX.Element => {
    const navigate = useNavigate();
    const { authenticated, loading, userInfo } = useAppSelector(selectAuth);
    const { pathname } = useLocation();

    const menus = useMemo(() => getMenusHasPermission(userInfo?.roles || []), [userInfo]);

    const hasPermissionOnRoute = menus.some(menu => {
        const index = menu.path.indexOf('/:');
        return menu.children
            ? menu.children.some(child => {
                const index = child.path.indexOf('/:');
                if(index !== -1)
                    return pathname.substring(0, index) === child.path;
                return pathname === child.path;
            })
            : index !== -1 ? pathname.substring(0, index) : pathname === menu.path;
    });

    if (!authenticated) navigate('/login');
    if (hasPermissionOnRoute && !loading) return props.children;
    return <Error status={403} title={'403'} subTitle={'Xin lỗi bạn không có quyền truy cập trang này'} />;
};

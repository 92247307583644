import Loading from 'components/loading/Loading';
import React from 'react';

interface Props
{
    loading?: JSX.Element;
    children: JSX.Element;
}

export const SuspenseWrapper: React.FC<Props> = (props): JSX.Element =>
{
    const { loading, children } = props;
    
    return (
        <React.Suspense fallback={loading ?? <Loading />}>{children}</React.Suspense>
    );
};

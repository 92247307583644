import { selectAuth } from 'app/authSlice';
import { useAppSelector } from 'app/hooks';
import { getMenusHasPermission } from 'components/layout/AppLayout';
import { ISidebarMenu } from 'components/layout/type';
import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

export const Redirect: React.FC = (): JSX.Element => {
    const { userInfo } = useAppSelector(selectAuth);
    const menus: Array<ISidebarMenu> = useMemo(() => getMenusHasPermission(userInfo?.roles || []), [userInfo]);

    const firstPermissionMenu = menus.find(x => x);

    return <Navigate to={firstPermissionMenu?.path ?? '/admin/dashboard'} />;
};

import { Modal } from 'antd';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

interface Props {
    [key: string]: any;
}

interface PayloadRef {
    width: string;
    title: string;
    form: JSX.Element;
}

export interface HModalRef {
    onOpen: (payload: PayloadRef) => void;
    onClose: () => void;
}

export const HModal = React.forwardRef<HModalRef, Props>((props, ref) => {
    const [visible, setVisible] = useState<boolean>(false);
    const payloadRef = useRef<PayloadRef>({} as PayloadRef);
    useImperativeHandle(
        ref,
        () => ({
            onOpen: (payload: PayloadRef) => {
                (payloadRef.current = payload), setVisible(true);
            },
            onClose: () => setVisible(false),
        }),
        [],
    );

    return (
        <Modal
            title={payloadRef.current.title}
            width={payloadRef.current.width}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            {payloadRef.current.form}
        </Modal>
    );
});

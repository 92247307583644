import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faCircleXmark, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification } from 'antd';

import NotificationConstant from '../constants/notificationConstant';

interface Props {
    title?: string;
    description: string;
}
export default class NotificationUtil
{
    static success({ title = NotificationConstant.TITLE, description }: Props) {
        notification.open({
            message: title,
            description: description,
            icon: <FontAwesomeIcon icon={faCircleCheck} color={'#52c41a'}/>,
            style: { backgroundColor: '#f6ffed', border: '1px solid #b7eb8f' },
        });
    }
    static error({ title = NotificationConstant.TITLE, description }: Props) {
        notification.open({
            message: title,
            description: description,
            icon: <FontAwesomeIcon icon={faCircleXmark} color={'#ff4d4f'} />,
            style: { backgroundColor: '#fff2f0', border: '1px solid #ffccc7' },
        });
    }
    static warning({ title = NotificationConstant.TITLE, description }: Props) {
        notification.open({
            message: title,
            description,
            icon: <FontAwesomeIcon icon={faExclamationCircle} color={'#faad14'} />,
            style: { backgroundColor: '#fffbe6', border: '1px solid #ffe58f' },
        });
    }
}
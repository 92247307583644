import { Error } from 'components/Error';
import { PublicAppLayout } from 'components/layout/PublicAppLayout';
import ManageAccount from 'module/manage-account/ManageAccount';
import { TestComponent } from 'module/test-components/TestComponent';
import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { PrivateRoute } from 'routes/PrivateRoute';
import { Redirect } from 'routes/Redirect';

const AppLayout = React.lazy(() => import('components/layout/AppLayout'));
const WaitingList = React.lazy(() => import('module/waiting-list/WaitingList'));
const Dashboard = React.lazy(() => import('module/dashboard/Dashboard'));
const MedicalExaminationRequest = React.lazy(
    () => import('module/medical-examination-request/MedicalExaminationRequest'),
);
const RoleClaims = React.lazy(() => import('module/role/components/RoleClaims'));
const MessagesView = React.lazy(() => import('module/messages/MessagesView'));
const PublicBooking = React.lazy(() => import('module/medical-examination-request/component/PublicBooking'));
const Notification = React.lazy(() => import('module/notification/SystemNotification'));
const PrivacyConfiguration = React.lazy(() => import('module/privacy-configuration/PrivacyConfiguration'));
const Role = React.lazy(() => import('module/role/Role'));
const CalendarPage = React.lazy(() => import('module/calendar/CalendarPage'));
const SupplyPage = React.lazy(() => import('module/supply/SupplyPage'));

const routes: Array<RouteObject> = [
    {
        path: '/',
        element: <Redirect />,
    },
    {
        path: '/admin',
        element: <AppLayout />,
        children: [
            {
                path: 'dashboard',
                element: (
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                ),
            },
            {
                path: 'message',
                element: (
                    <PrivateRoute>
                        <MessagesView />
                    </PrivateRoute>
                ),
            },
            {
                path: 'medical-examination-request',
                element: (
                    <PrivateRoute>
                        <MedicalExaminationRequest />
                    </PrivateRoute>
                ),
            },
            {
                path: 'waiting-list',
                element: (
                    <PrivateRoute>
                        <WaitingList />
                    </PrivateRoute>
                ),
            },
            {
                path: 'notification',
                element: (
                    <PrivateRoute>
                        <Notification />
                    </PrivateRoute>
                ),
            },
            {
                path: 'privacy-configuration',
                element: (
                    <PrivateRoute>
                        <PrivacyConfiguration />
                    </PrivateRoute>
                ),
            },
            {
                path: 'role',
                children: [
                    {
                        path: '',
                        element: (
                            <PrivateRoute>
                                <Role />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'claims/:id',
                        element: (
                            <PrivateRoute>
                                <RoleClaims />
                            </PrivateRoute>
                        ),
                    },
                ],
            },
            {
                path: 'manage-account',
                element: (
                    <PrivateRoute>
                        <ManageAccount />
                    </PrivateRoute>
                ),
            },
            {
                path: 'test-component',
                element: (
                    <PrivateRoute>
                        <TestComponent />
                    </PrivateRoute>
                ),
            },
            {
                path: 'calendar',
                element: (
                    <PrivateRoute>
                        <CalendarPage />
                    </PrivateRoute>
                ),
            },
            {
                path: 'supply',
                element: (
                    <PrivateRoute>
                        <SupplyPage />
                    </PrivateRoute>
                ),
            },
        ],
    },
    {
        path: '/public',
        element: <PublicAppLayout />,
        children: [
            {
                path: 'booking',
                element: <PublicBooking />,
            },
        ],
    },
    {
        path: '*',
        element: <Error title="404" status={404} subTitle={'Sorry, the page you visited does not exist.'} />,
    },
];

export const AppRouter = () => {
    const element = useRoutes(routes);
    return <React.Fragment>{element}</React.Fragment>;
};

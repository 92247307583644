import './App.css';
import './style/layout-app.scss';
import 'antd/dist/antd.min.css';
import 'style/index.scss';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppProvider } from 'providers/AppProvider';
import React from 'react';
import { AppRouter } from 'routes/AppRoute';

// Create a client
const queryClient = new QueryClient();

const App: React.FC = (): JSX.Element => {
    return (
        // Provide the client to your App
        <QueryClientProvider client={queryClient}>
            <AppProvider>
                <div className="App h-screen w-screen">
                    <div className="body">
                        <AppRouter />
                    </div>
                </div>
            </AppProvider>
        </QueryClientProvider>
    );
};

export default App;

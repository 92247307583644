import { Avatar, Layout } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const PublicAppLayout: React.FC = ():JSX.Element => {
    return (
        <Layout>
            <Layout>
                <Header
                    className="header"
                    style={{
                        position: 'fixed',
                        zIndex: 1,
                        width: '100%',
                        boxShadow:
        'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                    }}
                >
                    <div className="absolute left-5">
                        <Avatar size={50} src={'/logo.png'} />
                    </div>
                </Header>
                <Content
                    style={{
                        marginTop: 100,
                    }}
                >
                    <div
                        className="site-layout-background"
                        style={{
                            textAlign: 'center',
                            height: 'calc(100vh - 100px)',
                        }}
                    >
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

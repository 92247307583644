import 'components/style/loading-app.scss';

import React from 'react';

const Loading: React.FC = (): JSX.Element => {
    return (
        <div className='loading-container'>
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    );
};

export default Loading;

import './style/login-view.scss';

import { loginAsync } from 'app/authSlice';
import { useAppDispatch } from 'app/hooks';
import { UserLoginDto } from 'app/type';

import { FormLogin } from './component/FormLogin';

export const LoginView: React.FC = (): JSX.Element =>
{
    const dispatch = useAppDispatch();
    const onCheckLogin = (userInfo: UserLoginDto) => {
        dispatch(loginAsync(userInfo));
    };
    return (
        <div className="login-page">
            <div className="background-image w-full">
                <img src={'/background.jpg'} alt="" />
            </div>
            <div className="box-form-login">
                <FormLogin onCheckLogin={onCheckLogin} />
            </div>
        </div>
    );
};

export default LoginView;
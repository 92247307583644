import {
    faBell,
    faBookAtlas,
    faClipboardCheck,
    faClipboardList,
    faHistory,
    faHome,
    faLockOpen,
    faStethoscope,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISidebarMenu } from 'components/layout/type';
import MedicalPermission from 'constants/medicalPermission';
import RolePermission from 'constants/rolePermission';

export const SIDEBAR_MENU: Array<ISidebarMenu> = [
    {
        name: 'Tổng quan',
        path: '/admin/dashboard',
        icon: <FontAwesomeIcon icon={faHome} />,
        isDisplay: true,
    },
    {
        name: 'Thông báo',
        icon: <FontAwesomeIcon icon={faBell} />,
        path: '/admin/notification',
        isDisplay: true,
    },
    {
        name: 'Danh sách đặt khám',
        icon: <FontAwesomeIcon icon={faClipboardList} />,
        path: '/admin/medical-examination-request',
        isDisplay: true,
    },
    {
        name: 'Danh sách chờ khám',
        icon: <FontAwesomeIcon icon={faClipboardCheck} />,
        path: '/admin/waiting-list',
        isDisplay: true,
    },
    {
        name: 'Vật tư phòng mổ',
        icon: <FontAwesomeIcon icon={faStethoscope} />,
        path: '/admin/supply',
        permission: [MedicalPermission.VIEW_MEDICAL_SUPPLIES_ROLE],
        isDisplay: true,
    },
    {
        name: 'Lịch phòng mổ',
        icon: <FontAwesomeIcon icon={faHistory} />,
        path: '/admin/calendar',
        permission: [MedicalPermission.VIEW_CALENDAR_ROLE],
        isDisplay: true,
    },
    {
        name: 'Quản lý điều khoản',
        icon: <FontAwesomeIcon icon={faBookAtlas} />,
        path: '/admin/privacy-configuration',
        isDisplay: true,
    },
    {
        name: 'Quản lý vai trò',
        icon: <FontAwesomeIcon icon={faLockOpen} />,
        path: '/admin/role',
        permission: [RolePermission.VIEW_ROLE],
        isDisplay: true,
    },
    {
        name: '',
        icon: <></>,
        path: '/admin/role/claims/:id',
        permission: [RolePermission.VIEW_ROLE],
        isDisplay: false,
    },
    {
        name: 'Quản lý tài khoản',
        icon: <FontAwesomeIcon icon={faUser} />,
        path: '/admin/manage-account',
        isDisplay: true,
    },
    // {
    //     name: 'Test component',
    //     icon: <FontAwesomeIcon icon={faClipboardCheck} />,
    //     path: '/admin/test-component',
    // },
];

export const MAIN_ROUTE = [
    '/admin/dashboard',
    '/admin/medical-examination-request',
    '/admin/waiting-list',
    '/admin/notification',
    '/admin/supply',
    '/admin/calendar',
    '/admin/privacy-configuration',
    '/admin/role',
    '/admin/manage-account',
];

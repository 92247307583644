import { CHECK_LOGIN_API, LOGIN_API } from 'apis';
import { AxiosError } from 'axios';

import axiosInstance from '../configAxios/axiosInstance';
import NotificationConstant from '../constants/notificationConstant';
import { ApiRequest, ApiResponse } from '../types/api';
import NotificationUtil from './notificationUtil';

export default class ApiUtil {
    static GLOBAL_API = '/api';
    static requestApi = async <TResponse = void, TRequest = void>(
        request: ApiRequest<TRequest>,
    ): Promise<ApiResponse<TResponse>> => {
        const { method, url, config, data } = request;
        switch (method) {
            case 'GET':
                try {
                    const response = await axiosInstance.get<ApiResponse<TResponse>, ApiResponse<TResponse>>(
                        url,
                        config,
                    );
                    return response;
                } catch (error) {
                    if (error instanceof AxiosError && url !== CHECK_LOGIN_API) {
                        getErrorMessage(error);
                    }
                    throw error;
                }
            case 'POST':
                try {
                    const response = await axiosInstance.post<ApiResponse<TResponse>, ApiResponse<TResponse>>(
                        url,
                        data,
                        config,
                    );
                    return response;
                } catch (error) {
                    if (error instanceof AxiosError && url !== LOGIN_API) {
                        getErrorMessage(error);
                    }
                    throw error;
                }
            case 'PUT':
                try {
                    const response = await axiosInstance.put<ApiResponse<TResponse>, ApiResponse<TResponse>>(
                        url,
                        data,
                        config,
                    );
                    return response;
                } catch (error) {
                    if (error instanceof AxiosError) {
                        getErrorMessage(error);
                    }
                    throw error;
                }
            default: {
                try {
                    const response = await axiosInstance.delete<ApiResponse<TResponse>, ApiResponse<TResponse>>(
                        url,
                        config,
                    );
                    return response;
                } catch (error) {
                    if (error instanceof AxiosError) {
                        getErrorMessage(error);
                    }
                    throw error;
                }
            }
        }
    };
}

const getErrorMessage = (error: AxiosError) => {
    switch (error.response?.status) {
        case 401:
            NotificationUtil.warning({ description: NotificationConstant.EXPIRED_SESSION });
            break;
        case 400: {
            const result = error.response.data as ApiResponse<Record<string, string>>;
            NotificationUtil.error({ description: result.message ?? NotificationConstant.BAD_REQUEST });
            break;
        }
        default:
            NotificationUtil.error({ description: NotificationConstant.SERVER_ERROR });
            break;
    }
};

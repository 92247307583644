import { AxiosRequestConfig } from 'axios';

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface BaseApiResponse {
    success: boolean;
    message?: string;
    error?: string;
    status: number;
}

export interface ApiResponse<T> extends BaseApiResponse {
    results?: T;
}


export interface Pagination<T> {
    items?: Array<T>;
    offset: number;
    limit: number;
    total: number;
    currentPage: number;
    totalPage: number;
}

export interface PaginationQuery
{
    offset: number;
    limit: number;
    searchKey: string;
    sort: {
        [key: string]: string;
    },
    filter: {
        [key: string]: Filter
    }
}

export interface Filter
{
    operator: Operator,
    value: any,
    logic: Logic
}

export enum Operator{
    Contains,
    Equals,
}

export enum Logic
    {
    And,
    Or,
}


export interface BaseApiRequest {
    method: Method;
    url: string;
    config?: AxiosRequestConfig;
}

export interface ApiRequest<T> extends BaseApiRequest {
    data?: T;
}

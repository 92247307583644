import { Button, Result } from 'antd';
import React from 'react';

type Status = 403 | 404 | 500;

interface Props {
    status: Status;
    title: string;
    subTitle: string;
}

export const Error: React.FC<Props> = (props: Props): JSX.Element => {
    const { status, title, subTitle } = props;
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
                extra={
                    <Button href="/" type="primary">
            Back Home
                    </Button>
                }
            />
        </div>
    );
};

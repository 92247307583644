

import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { SuspenseWrapper } from 'components/SuspenseWrapper';
import { AuthProvider } from 'providers/AuthProvider';
import React from 'react';
import {BrowserRouter as Router } from 'react-router-dom';

interface Props
{
    children: JSX.Element
}

export const AppProvider : React.FC<Props> = ({children}): JSX.Element => {
    return (
        <SuspenseWrapper>
            <ErrorBoundary>
                <Router>
                    <AuthProvider>
                        {children}
                    </AuthProvider>
                </Router>
            </ErrorBoundary>
        </SuspenseWrapper>
    );
};

import { DetailGridInfo, GridReadyEvent, IDatasource, IGetRowsParams, SortModelItem } from 'ag-grid-community';
import { GridController, GridModel, HStore } from 'components/grid/type';
import { useMergeState } from 'components/hooks/useMergeState';
import NotificationConstant from 'constants/notificationConstant';
import { useEffect, useRef } from 'react';
import { Filter, Operator, Pagination, PaginationQuery } from 'types';
import ApiUtil from 'utils/apiUtil';
import NotificationUtil from 'utils/notificationUtil';

type Props = HStore;

interface State {
    loading: boolean;
    pageSize: number;
}

export const DEFAULT_PAGE_SIZE = 20;

const useGridController = <TModel>(props: Props): GridController => {
    const { url, limit = DEFAULT_PAGE_SIZE, extraParams } = props;
    const [{ loading, pageSize }, setState] = useMergeState<State>({
        loading: false,
        pageSize: limit,
    });
    const gridRef = useRef<DetailGridInfo>({
        id: 'H_Grid',
        api: undefined,
        columnApi: undefined,
    });

    const onChangePageSize = (pageSize: number) => {
        setState({ pageSize: pageSize, loading: true });
    };

    const fetchData = async () => {
        const dataSource: IDatasource = {
            getRows: async (params: IGetRowsParams) => {
                try {
                    const filterDic = {} as any;
                    for (const [key, value] of Object.entries<any>(params.filterModel)) {
                        const filter = {
                            operator: value['type'] === 'contains' ? Operator.Contains : Operator,
                            value: value['filter'],
                        } as Filter;
                        filterDic[key] = filter;
                    }
                    const sortDic = {} as any;

                    params.sortModel.forEach((item: SortModelItem) => {
                        sortDic[item.colId] = item.sort;
                    });

                    gridRef.current.api?.showLoadingOverlay();
                    const offset = params.startRow;
                    const response = await ApiUtil.requestApi<Pagination<GridModel<TModel>>>({
                        method: 'GET',
                        url: url,
                        config: {
                            params: {
                                limit: pageSize,
                                offset: offset,
                                filter: { ...filterDic },
                                sort: { ...sortDic },
                                ...extraParams,
                            } as PaginationQuery,
                        },
                    });
                    if (response.success) {
                        if (pageSize === -1) {
                            const results = response.results as any;
                            const data = results as Array<any>;
                            params.successCallback(data, data.length);
                        } else {
                            params.successCallback(response.results?.items || [], response.results?.total);
                        }
                    } else {
                        NotificationUtil.error({ description: NotificationConstant.SERVER_ERROR });
                        params.successCallback([], 0);
                    }
                } catch (error) {
                    NotificationUtil.error({ description: NotificationConstant.SERVER_ERROR });
                    params.successCallback([], 0);
                } finally {
                    gridRef.current.api?.hideOverlay();
                }
            },
        };
        gridRef?.current?.api?.setDatasource(dataSource);
    };
    const onGridReady = (event: GridReadyEvent) => {
        gridRef.current.api = event.api;
        gridRef.current.columnApi = event.columnApi;
        gridRef.current.api.sizeColumnsToFit();
        fetchData();
    };

    const onReload = () => {
        fetchData();
    };

    const maskGrid = () => {
        gridRef.current.api?.showLoadingOverlay();
    };

    const unmaskGrid = () => {
        gridRef.current.api?.hideOverlay();
    };

    useEffect(() => {
        fetchData();
    }, [pageSize]);

    return {
        loading,
        paginationPageSize: pageSize,
        cacheBlockSize: pageSize,
        onGridReady,
        onChangePageSize,
        onReload,
        maskGrid,
        unmaskGrid,
    };
};

export default useGridController;

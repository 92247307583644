import 'components/style/form.scss';

import { Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ButtonActionType, ButtonFormClick, FormButton, FormModel, FormModelConfig } from 'components/form/type';
import { FormLoading } from 'components/loading/FormLoading';
import NotificationConstant from 'constants/notificationConstant';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import NotificationUtil from 'utils/notificationUtil';

interface Props {
    children: JSX.Element;
    formModel: FormModelConfig;
    initialValues?: FormModel;
    buttons?: Array<FormButton>;
    shouldResetField?: boolean;
}

export interface HFormRef {
    getFieldValue: (name: string) => any;
    getFieldsValue: () => FormModel;
    setFieldsValue: (values: FormModel) => void;
    setFieldValue: (name: string, value: any) => void;
    mask: () => void;
    unmask: () => void;
    resetFormValues: () => void;
}
export const FormContext = React.createContext<FormModelConfig>({} as FormModelConfig);

export const HForm = React.forwardRef<HFormRef, Props>((props, ref) => {
    const [form] = useForm<FormModel>();
    const [loading, setLoading] = useState<boolean>(false);
    const { children, formModel, initialValues, buttons, shouldResetField = true } = props;

    useImperativeHandle(
        ref,
        () => ({
            getFieldValue: (name: string) => form.getFieldValue(name),
            getFieldsValue: () => form.getFieldsValue(),
            setFieldsValue: (values: FormModel) => form.setFieldsValue({ ...values }),
            setFieldValue: (name: string, value: any) => form.setFields([{ name: name, value: value }]),
            mask: () => setLoading(true),
            unmask: () => setLoading(false),
            resetFormValues: () => form.resetFields(),
        }),
        [],
    );

    const onButtonClick = async (onClick: ButtonFormClick, actionType: ButtonActionType) => {
        switch (actionType) {
            case 'submit':
                try {
                    const formValues = await form.validateFields();
                    onClick(formValues);
                    if (shouldResetField) {
                        form.setFieldsValue({});
                        form.resetFields();
                    }
                } catch (error) {
                    const { errorFields } = error as ValidateErrorEntity;
                    NotificationUtil.error({
                        description:
                            errorFields.find(x => x)?.errors.find(x => x) ?? NotificationConstant.INVALID_INPUT_DATA,
                    });
                }
                break;
            default:
                onClick(form.getFieldsValue());
                form.setFieldsValue({});
                form.resetFields();
                break;
        }
    };

    useEffect(() => {
        form.setFieldsValue({ ...initialValues });
    }, [initialValues]);

    return (
        <FormContext.Provider value={formModel}>
            <div className="flex flex-col">
                <div className="w-auto h-auto">
                    <Form form={form}>{children}</Form>
                </div>
                <div className="flex flex-row justify-center">
                    {buttons?.map((button, index) => {
                        const { name, onClick, actionType, ...rest } = button;
                        return (
                            <Button
                                className="mx-1"
                                style={{ padding: '0 20px 0 20px' }}
                                key={index}
                                {...rest}
                                onClick={() => onButtonClick(onClick, actionType)}
                            >
                                {name}
                            </Button>
                        );
                    })}
                </div>
            </div>
            <FormLoading loading={loading} />
        </FormContext.Provider>
    );
});

export default class NotificationConstant {
    static TITLE = 'Thông báo';

    //#region auth
    static LOGIN_FAILED = 'Đăng nhập thất bại';
    static EXPIRED_SESSION = 'Hết phiên làm việc vui lòng đang nhập lại';
    static BAD_REQUEST = 'Dữ liệu không hợp lệ';
    //#endregion

    //#region Common
    static CREATE_SUCCESS = 'Tạo mới thành công';
    static CREATE_FAILED = 'Tạo mới thất bại';
    static UPDATE_SUCCESS = 'Cập nhật thành công';
    static UPDATE_FAILED = 'Cập nhật thất bại';
    static SERVER_ERROR = 'Có lỗi xảy ra vui lòng thử lại';
    static DELETE_SUCCESS = 'Xóa thành công';
    static DELETE_FAILED = 'Xóa thất bại vui lòng thử lại';
    static INVALID_INPUT_DATA = 'Dự liệu không hợp lệ';
    //#endregion

    //#region Medical Examination
    static BOOKING_SUCCESS = 'Đặt lịch khám thành công';
    static BOOKING_FAILED = 'Đặt lịch khám không thành công';

    //#endregion
}

import { Identifier } from 'types';

export interface UserAccountDto {
    id: Identifier;
    userName: string;
    phoneNumber: string;
    fullName: string;
    isActive: boolean;
    userType: UserType;
}

export interface CreateAccountDto {
    userName: string;
    fullName: string;
    password: string;
    confirmPassword: string;
    // identity: string;
    phoneNumber: string;
}

export interface UpdateAccountDto {
    fullName: string;
    phoneNumber: string;
}

export enum UserType {
    Employee,
    User,
}

import { useState } from 'react';

type DispatchAction<T> = (prevState: T) => Partial<T>;

type SetStatePayload<T> = Partial<T> | DispatchAction<T>;

type MergeState<T> = [state: T, setMergeState: (payload: SetStatePayload<T>) => void];

export const useMergeState = <T = Record<string, any>>(initialState: T): MergeState<T> => {
    const [state, setState] = useState<T>(initialState);

    const setMergeState = (payload: SetStatePayload<T>) => {
        if (typeof payload === 'function') {
            setState(payload as any);
        }

        setState(prevState => ({ ...prevState, ...payload }));
    };

    return [state, setMergeState];
};

import { FormContext } from 'components/form/HForm';
import { BaseFieldProps, FieldProps } from 'components/form/type';
import React, { useContext } from 'react';


export const WithFormConfig = <P extends FieldProps, >(WrappedComponent: React.FC<P>) => (props: Omit<P, keyof BaseFieldProps>) : JSX.Element =>
{
    const formModel = useContext(FormContext);
    const { name } = props;
    const formFieldConfig = formModel[name];
    const { inputConfig, showPlaceholder, ...config } = formFieldConfig || {};
    const placeholder = inputConfig?.placeholder ? inputConfig?.placeholder : `Nhập ${ config.label?.toLocaleLowerCase() }`;
    return (
        <WrappedComponent
            {...props as P}
            inputConfig={
                {
                    ...inputConfig,
                    placeholder: showPlaceholder ? placeholder : undefined
                }
            }
            formFieldConfig={
                {
                    ...config,
                    name: name,
                }
            }
        />);
};
import { Form } from 'antd';
import { FieldProps } from 'components/form/type';
import { WithFormConfig } from 'components/form/WithFormConfig';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DualListBox from 'react-dual-listbox';
import { ComboOptions } from 'types';
import CommonUtil from 'utils/commonUtil';

interface Props extends FieldProps {
    options: Array<ComboOptions<any>>;
    initialValues?: Array<any>;
}
const { Item } = Form;
const BaseDualListBoxField: React.FC<Props> = (props): JSX.Element => {
    const { formFieldConfig, inputConfig, initialValues, ...restConfig } = props;
    const [selected, setSelected] = useState<Array<any>>([]);

    useEffect(() => {
        setSelected(initialValues || []);
    }, [initialValues]);

    return (
        <Item {...formFieldConfig}>
            <DualListBox
                {...inputConfig}
                {...restConfig}
                canFilter
                selected={selected}
                onChange={selected => setSelected(selected)}
                filterCallback={(options, value) =>
                    CommonUtil.removeVietnameseSign(options?.label?.toString() || '').includes(
                        CommonUtil.removeVietnameseSign(value),
                    )
                }
            />
        </Item>
    );
};

export const DualListBoxField = WithFormConfig(BaseDualListBoxField);

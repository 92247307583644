import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community';
import { Switch } from 'antd';
import { HModal, HModalRef } from 'components/form/HModal';
import { HGrid } from 'components/grid/HGrid';
import { ButtonToolbar, ColumnActions } from 'components/grid/type';
import useGridController from 'components/grid/useGridController';
import { SuspenseWrapper } from 'components/SuspenseWrapper';
import NotificationConstant from 'constants/notificationConstant';
import { CHANGE_USER_STATUS_API, GET_USER_ACCOUNT_API } from 'module/manage-account/api';
const UpdateAccountForm = React.lazy(() => import('module/manage-account/components/UpdateAccountForm'));
const CreateAccountForm = React.lazy(() => import('module/manage-account/components/CreateAccountForm'));
import { manageAccountColDefs } from 'module/manage-account/configs/manageAccountColDefs';
import { UserAccountDto } from 'module/manage-account/type';
import React, { useCallback, useRef } from 'react';
import ApiUtil from 'utils/apiUtil';
import NotificationUtil from 'utils/notificationUtil';

const ManageAccount: React.FC = (): JSX.Element => {
    const gridController = useGridController<UserAccountDto>({
        url: GET_USER_ACCOUNT_API,
        autoLoad: true,
    });
    const modalRef = useRef<HModalRef>(null);

    const onUpdate = (rowData: UserAccountDto) => {
        modalRef.current?.onOpen({
            form: (
                <SuspenseWrapper>
                    <UpdateAccountForm
                        rowData={rowData}
                        onClose={modalRef.current?.onClose}
                        onSuccess={() => {
                            gridController.onReload();
                            modalRef.current?.onClose();
                        }}
                    />
                </SuspenseWrapper>
            ),
            title: 'Cập nhật thông tin tài khoản',
            width: '60%',
        });
    };

    const onCreate = () => {
        modalRef.current?.onOpen({
            form: (
                <SuspenseWrapper>
                    <CreateAccountForm
                        onClose={modalRef.current?.onClose}
                        onSuccess={() => {
                            gridController.onReload();
                            modalRef.current?.onClose();
                        }}
                    />
                </SuspenseWrapper>
            ),
            title: 'Tạo tài khoản',
            width: '40%',
        });
    };

    const onChangeStatus = (checked: boolean, rowData: UserAccountDto) => {
        ApiUtil.requestApi({
            method: 'PUT',
            url: CHANGE_USER_STATUS_API,
            data: {
                isActive: checked,
                userId: rowData.id,
            },
        })
            .then(res => {
                if (res.success)
                    NotificationUtil.success({
                        title: NotificationConstant.TITLE,
                        description: NotificationConstant.UPDATE_SUCCESS,
                    });
            })
            .catch(err => {
                NotificationUtil.error({
                    title: NotificationConstant.TITLE,
                    description: NotificationConstant.UPDATE_FAILED,
                });
            });
    };

    const renderColumnActions = useCallback((): Array<ColumnActions<UserAccountDto>> => {
        return [
            {
                type: 'primary',
                onClick: onUpdate,
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                isConfirm: false,
            },
        ];
    }, []);

    const renderToolbar = useCallback((): Array<ButtonToolbar> => {
        return [
            {
                name: 'Tạo mới',
                onClick: onCreate,
                type: 'primary',
                icon: <FontAwesomeIcon icon={faPlusCircle} />,
            },
        ];
    }, []);

    const cellRenderer = (params: ICellRendererParams) => {
        const data = params.data as UserAccountDto;
        return <Switch onChange={checked => onChangeStatus(checked, data)} defaultChecked={data?.isActive} />;
    };

    return (
        <>
            <HGrid
                gridController={gridController}
                columnDefs={manageAccountColDefs(cellRenderer)}
                columnActions={renderColumnActions()}
                rowsCount={true}
                floatingFilter={true}
                pagination={true}
                hasSelectPageSize={true}
                buttonsToolbar={renderToolbar()}
            />
            <HModal ref={modalRef} />
        </>
    );
};
export default ManageAccount;

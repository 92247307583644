import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CHECK_LOGIN_API, LOGIN_API, LOGOUT_LOGIN_API } from 'apis';
import { AppThunk, RootState } from 'app/store';
import { UserLoginDto } from 'app/type';
import NotificationConstant from 'constants/notificationConstant';
import { LoginResponseDto, UserDetailDto } from 'types';
import ApiUtil from 'utils/apiUtil';
import NotificationUtil from 'utils/notificationUtil';


interface State
{
    loading: boolean;
    authenticated: boolean;
    userInfo: UserDetailDto | null
}


const initialState : State = {
    authenticated: false,
    loading: false,
    userInfo: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthData: (state: State, action: PayloadAction<UserDetailDto | null>) =>
        {
            state.authenticated = true;
            state.userInfo = action.payload;
        },
        setLoading: (state: State, action: PayloadAction<boolean>) =>
        {
            state.loading = action.payload;
        },
        setLogout: (state: State) =>
        {
            state.userInfo = null;
            state.authenticated = false;
        }
    }
});


export const {setAuthData, setLoading, setLogout} = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;
const authReducer = authSlice.reducer;

export default authReducer;


export const loginAsync = (userLoginDto: UserLoginDto): AppThunk => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await ApiUtil.requestApi<LoginResponseDto, UserLoginDto>({method: 'POST', url: LOGIN_API, data: userLoginDto});
        if (response.success) {
            dispatch(setAuthData(response.results?.userInfo ?? null));
        } else {
            NotificationUtil.error({description: NotificationConstant.LOGIN_FAILED});
        }
    } catch (error) {
        NotificationUtil.error({description: NotificationConstant.LOGIN_FAILED});
    } finally
    {   
        dispatch(setLoading(false));
    }
};

export const checkLogin = (): AppThunk => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await ApiUtil.requestApi<UserDetailDto, UserLoginDto>({method: 'GET', url: CHECK_LOGIN_API});
        if (response.success) {
            dispatch(setAuthData(response.results ?? null));
        }
    } catch (error) {
        NotificationUtil.warning({description: NotificationConstant.EXPIRED_SESSION});
    } finally
    {
        dispatch(setLoading(false));
    }
   
};

export const logout = (): AppThunk => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await ApiUtil.requestApi<void, UserLoginDto>({method: 'GET', url: LOGOUT_LOGIN_API});
        if (response.success) {
            dispatch(setLogout());
        }
    } catch (error) {
        NotificationUtil.warning({description: NotificationConstant.EXPIRED_SESSION});
    } finally
    {
        dispatch(setLoading(false));
    }
   
};
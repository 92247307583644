import 'components/style/header-app.scss';

import { UserOutlined } from '@ant-design/icons';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Dropdown, Menu } from 'antd';
import { HeaderProps } from 'interface/PropsType';


function HeaderApp({ onLogout, userInfo }: HeaderProps) {
    return (
        <div className='header-custom'>
            <Dropdown
                trigger={['click']}
                overlay={
                    <Menu
                        items={[
                            {
                                label: 'Logout',
                                key: 'Logout',
                                onClick: onLogout,
                                icon: <FontAwesomeIcon icon={faRightToBracket} />,
                            },
                        ]}
                    />
                }
            >
                <div style={{ marginRight: 25 }}>
                    <Avatar style={{ backgroundColor: '#2259a8' }} icon={<UserOutlined />} />
                    <span className='ml-2 font-bold text-[#2259a8]'>{userInfo?.fullName}</span>
                </div>
            </Dropdown>
        </div>
    );
}

export default HeaderApp;

import { DualListBoxField } from 'components/form/DualListBoxField';
import { HForm, HFormRef } from 'components/form/HForm';
import { HModal, HModalRef } from 'components/form/HModal';
import { TextField } from 'components/form/TextField';
import { FormModel, FormModelConfig } from 'components/form/type';
import { HGrid } from 'components/grid/HGrid';
import useGridController from 'components/grid/useGridController';
import React, { useRef } from 'react';

export const TestComponent: React.FC = (): JSX.Element => {
    const modalRef = useRef<HModalRef>(null);
    const formRef = useRef<HFormRef>(null);
    return (
        <div>
            TestComponent
            <button
                onClick={() => {
                    modalRef.current?.onOpen({
                        form: (
                            <HForm
                                ref={formRef}
                                formModel={
                                    {
                                        test: {
                                            name: 'abc',
                                        },
                                    } as FormModelConfig<{ test: Array<string> }>
                                }
                                // initialValues={{ a: 'abc' } as FormModel<{ a: string }>}
                                buttons={[
                                    {
                                        name: 'click',
                                        type: 'primary',
                                        actionType: 'other',
                                        onClick: formValues => {
                                            // formRef.current?.unmask();
                                            console.log('🚀 ~ file: TestComponent.tsx:39 ~ formValues', formValues);
                                        },
                                    },
                                    {
                                        name: 'click',
                                        type: 'primary',
                                        danger: true,
                                        actionType: 'submit',
                                        onClick: formValues => {
                                            // formRef.current?.mask();
                                        },
                                    },
                                ]}
                            >
                                <>
                                    <div className="abc">
                                        <DualListBoxField
                                            name="test"
                                            options={[
                                                {
                                                    label: 'abc',
                                                    value: 'def',
                                                },
                                            ]}
                                        />
                                    </div>
                                </>
                            </HForm>
                        ),
                        title: 'test',
                        width: '90%',
                    });
                }}
            >
                Click nè
            </button>
            <HModal ref={modalRef} />
        </div>
    );
};

import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { UserAccountDto, UserType } from 'module/manage-account/type';
import { nameof } from 'ts-simple-nameof';

export const manageAccountColDefs = (
    cellRenderer: (params: ICellRendererParams) => void,
): Array<ColDef<UserAccountDto>> => {
    return [
        {
            field: nameof<UserAccountDto>(x => x.userName),
            headerName: 'Tài khoản',
            cellStyle: { textAlign: 'start' },
            flex: 1,
            width: 150,
            tooltipField: nameof<UserAccountDto>(x => x.userName),
            resizable: true,
            sortable: true,
            filter: true,
        },
        {
            field: nameof<UserAccountDto>(x => x.fullName),
            headerName: 'Họ và tên',
            cellStyle: { textAlign: 'start' },
            flex: 1,
            width: 200,
            tooltipField: nameof<UserAccountDto>(x => x.fullName),
            resizable: true,
            sortable: true,
            filter: true,
        },
        {
            field: nameof<UserAccountDto>(x => x.phoneNumber),
            headerName: 'Số điện thoại',
            cellStyle: { textAlign: 'right' },
            flex: 1,
            width: 100,
            tooltipField: nameof<UserAccountDto>(x => x.phoneNumber),
            resizable: true,
            sortable: true,
            filter: true,
        },
        {
            field: nameof<UserAccountDto>(x => x.fullName),
            headerName: 'Loại tài khoản',
            cellStyle: { textAlign: 'center' },
            flex: 1,
            width: 100,
            tooltipField: nameof<UserAccountDto>(x => x.fullName),
            resizable: true,
            sortable: true,
            filter: false,
            valueFormatter: (params: ValueFormatterParams<UserAccountDto>) => {
                switch (params.data?.userType) {
                    case UserType.Employee:
                        return 'Nhân viên';
                    default:
                        return 'Người dùng';
                }
            },
        },
        {
            field: nameof<UserAccountDto>(x => x.isActive),
            headerName: 'Trạng thái',
            cellStyle: { textAlign: 'center' },
            flex: 1,
            width: 70,
            tooltipField: nameof<UserAccountDto>(x => x.isActive),
            resizable: true,
            sortable: true,
            filter: false,
            cellRenderer: cellRenderer,
        },
    ];
};

import { Menu } from 'antd';
import { Avatar, Layout } from 'antd/lib';
import SubMenu from 'antd/lib/menu/SubMenu';
import { logout, selectAuth } from 'app/authSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import HeaderApp from 'components/layout/HeaderApp';
import { MAIN_ROUTE, SIDEBAR_MENU } from 'components/layout/layoutConfig';
import { ISidebarMenu } from 'components/layout/type';
import Loading from 'components/loading/Loading';
import { useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

const { Header, Content, Sider } = Layout;

const AppLayout: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { userInfo, loading } = useAppSelector(selectAuth);

    const { pathname } = useLocation();

    const defaultActiveKey = useMemo(() => getActiveKey(pathname), [pathname]);

    const onLogout = () => {
        dispatch(logout());
    };

    const menus: Array<ISidebarMenu> = useMemo(() => getMenusHasPermission(userInfo?.roles || []), [userInfo]);

    if (loading) return <Loading />;

    const siderWidth = 250;

    return (
        <Layout hasSider>
            <Sider
                className="site-layout-background"
                trigger
                width={siderWidth}
                style={{
                    overflow: 'hidden',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 2,
                }}
            >
                <div className="slider-menu">
                    <div className="logo-app">Thai Hoa Clinic</div>
                    <div className="info-house">
                        <Avatar size={100} src={'/logo.png'} />
                    </div>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[defaultActiveKey]}
                        style={{
                            height: '100%',
                            borderRight: 0,
                        }}
                    >
                        {menus
                            .filter(item => item.isDisplay)
                            .map((menu, index) => {
                                return menu.children ? (
                                    <SubMenu key={menu.path ? menu.path : index} icon={menu.icon} title={menu.name}>
                                        {menu.children.map(subMenu => (
                                            <Menu.Item key={subMenu.path} icon={subMenu.icon}>
                                                <Link to={subMenu.path}>{subMenu.name}</Link>
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Menu.Item icon={menu.icon} key={menu.path}>
                                        <Link to={menu.path || ''}>{menu.name}</Link>
                                    </Menu.Item>
                                );
                            })}
                    </Menu>
                </div>
            </Sider>
            <Layout>
                <Header
                    className="header"
                    style={{
                        position: 'fixed',
                        zIndex: 1,
                        width: '100%',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                    }}
                >
                    <HeaderApp userInfo={userInfo} onLogout={onLogout} />
                </Header>
                <Content
                    style={{
                        overflow: 'hidden',
                        marginTop: 31,
                    }}
                >
                    <div
                        className="site-layout-background"
                        style={{
                            padding: '24px 0px 0px 24px',
                            textAlign: 'center',
                            marginLeft: siderWidth - 25,
                            height: 'calc(100vh - 31px)',
                            overflow: 'auto',
                        }}
                    >
                        <Outlet />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

const getActiveKey = (pathname: string): string => {
    for (const item of MAIN_ROUTE) {
        if (pathname.includes(item)) return item;
    }
    return MAIN_ROUTE[0];
};
export default AppLayout;

export const getMenusHasPermission = (currentUserRoles: Array<string>): Array<ISidebarMenu> => {
    const menus = [] as Array<ISidebarMenu>;
    for (const item of SIDEBAR_MENU) {
        if (!item.permission) {
            menus.push(item);
            continue;
        }
        const hasPermission = currentUserRoles.some(role => item.permission?.includes(role));
        if (hasPermission) {
            menus.push(item);
        }
    }
    return menus;
};

import moment from 'moment';


export default class CommonUtil
{
    static removeVietnameseSign = (input: string) : string => {
        return input
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd')
            .replace(/Đ/g, 'D')
            .toLowerCase();
    };

    static formatDateTime = (format: string, date: Date) =>
    {
        return moment(date).format(format);
    };

}